import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_NAN_PORT}${process.env.VUE_APP_API_PATH}/nan`;


export default {
    obterTodos(filtro) {
        return axiosJwt.get(`${api}/atendimentos?dataAtendimentoDe=${filtro.dataAtendimentoDe}&dataAtendimentoAte=${filtro.dataAtendimentoAte}&status=${filtro.status}`);
    },

    obterPorId(id) {
        return axiosJwt.get(`${api}/atendimentos/${id}`);
    },

};
