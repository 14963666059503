<template>
    <painel :titulo="`NAN - Atendimento`" icone="pi pi-comments" :refreshFunction="obterAtendimento">
        <div class="mb-4">
            <TabView v-model:activeIndex="activeIndex">
                <TabPanel header="Dados Gerais">
                    <detalhe titulo="Código" :size="size">
                        {{ atendimento?.diagnosticoId }}
                    </detalhe>
                    <detalhe titulo="Data Atendimento" :size="size">
                        {{ $dateFormat(atendimento?.dataAtendimento, 'DD/MM/YYYY') }}
                    </detalhe>
                    <detalhe titulo="Projeto" :size="size">
                        {{ atendimento?.projeto }}
                    </detalhe>
                    <detalhe titulo="Ação" :size="size">
                        {{ atendimento?.acao }}
                    </detalhe>
                    <detalhe titulo="Status" :size="size">
                        <status :status="atendimento?.statusIntegracaoDescricao"></status>
                    </detalhe>
                </TabPanel>
                <TabPanel header="Integração Consultoria">
                    <objeto-integracao-consultoria :atendimento="atendimento"></objeto-integracao-consultoria>
                </TabPanel>
                <TabPanel header="Integração Orientação">
                    <objeto-integracao-orientacao :atendimento="atendimento"></objeto-integracao-orientacao>
                </TabPanel>
            </TabView>
        </div>
        <div class="mb-4"></div>
        <btn-voltar :route="{ name: 'NAN_Atendimentos' }"></btn-voltar>
    </painel>
</template>

<script>
import AtendimentosService from './services';
import ObjetoIntegracaoConsultoria from './ObjetoIntegracaoConsultoria';
import ObjetoIntegracaoOrientacao from './ObjetoIntegracaoOrientacao';

export default {
    components: {
        ObjetoIntegracaoConsultoria,
        ObjetoIntegracaoOrientacao,
    },
    props: {},

    data() {
        return {
            atendimento: null,
            activeIndex: 0,
            size: '140',
        };
    },

    methods: {
        obterAtendimento() {
            this.$store.dispatch('addRequest');
            AtendimentosService.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.atendimento = response.data;
                } else {
                    this.atendimento = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizarAtendimento(atendimentoAtualizado) {
            this.atendimento = atendimentoAtualizado;
        },
    },

    computed: {},

    mounted() {
        this.obterAtendimento();
    },
};
</script>
