<template>
    
    <detalhe titulo="Objeto Integração" :size="size">
        <vue-json-pretty
            :data="JSON.parse(atendimento?.objetoIntegracaoOrientacaoTecnica)"
            :showDoubleQuotes="true"
            v-if="atendimento?.objetoIntegracaoOrientacaoTecnica && atendimento?.objetoIntegracaoOrientacaoTecnica?.isJson()"
        ></vue-json-pretty>
    </detalhe>
    <detalhe titulo="Retorno Integração" :size="size">
        <vue-json-pretty
            :data="JSON.parse(atendimento?.objetoRetornoOrientacaoTecnica)"
            :showDoubleQuotes="true"
            v-if="atendimento?.objetoRetornoOrientacaoTecnica && atendimento?.objetoRetornoOrientacaoTecnica?.isJson()"
        ></vue-json-pretty>
        <span v-if="atendimento?.objetoRetornoOrientacaoTecnica && !atendimento?.objetoRetornoOrientacaoTecnica?.isJson()">
            {{ atendimento?.objetoRetornoOrientacaoTecnica }}</span
        >
    </detalhe>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    components: {
        VueJsonPretty,
    },

    props: {
        atendimento: {
            type: Object,
        },
    },

    data() {
        return {
            size: '140',
        };
    },
};
</script>
